export const AgbModal = () => import('../../components/AgbModal.vue' /* webpackChunkName: "components/agb-modal" */).then(c => wrapFunctional(c.default || c))
export const AngebotsuebersichtApiData = () => import('../../components/AngebotsuebersichtApiData.vue' /* webpackChunkName: "components/angebotsuebersicht-api-data" */).then(c => wrapFunctional(c.default || c))
export const ContactInfo = () => import('../../components/ContactInfo.vue' /* webpackChunkName: "components/contact-info" */).then(c => wrapFunctional(c.default || c))
export const Countdown = () => import('../../components/Countdown.vue' /* webpackChunkName: "components/countdown" */).then(c => wrapFunctional(c.default || c))
export const CovidDisclaimer = () => import('../../components/CovidDisclaimer.vue' /* webpackChunkName: "components/covid-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const CustomerFeedback = () => import('../../components/CustomerFeedback.vue' /* webpackChunkName: "components/customer-feedback" */).then(c => wrapFunctional(c.default || c))
export const DiscountDetails = () => import('../../components/DiscountDetails.vue' /* webpackChunkName: "components/discount-details" */).then(c => wrapFunctional(c.default || c))
export const ItemCards = () => import('../../components/ItemCards.vue' /* webpackChunkName: "components/item-cards" */).then(c => wrapFunctional(c.default || c))
export const Klimaneutral = () => import('../../components/Klimaneutral.vue' /* webpackChunkName: "components/klimaneutral" */).then(c => wrapFunctional(c.default || c))
export const LanguageSwitcher = () => import('../../components/LanguageSwitcher.vue' /* webpackChunkName: "components/language-switcher" */).then(c => wrapFunctional(c.default || c))
export const Leistungen = () => import('../../components/Leistungen.vue' /* webpackChunkName: "components/leistungen" */).then(c => wrapFunctional(c.default || c))
export const RadioButtonTab = () => import('../../components/RadioButtonTab.vue' /* webpackChunkName: "components/radio-button-tab" */).then(c => wrapFunctional(c.default || c))
export const Reisepackages = () => import('../../components/Reisepackages.vue' /* webpackChunkName: "components/reisepackages" */).then(c => wrapFunctional(c.default || c))
export const TeilnehmerAngabenForm = () => import('../../components/TeilnehmerAngabenForm.vue' /* webpackChunkName: "components/teilnehmer-angaben-form" */).then(c => wrapFunctional(c.default || c))
export const Transport = () => import('../../components/Transport.vue' /* webpackChunkName: "components/transport" */).then(c => wrapFunctional(c.default || c))
export const VersicherungInformation = () => import('../../components/VersicherungInformation.vue' /* webpackChunkName: "components/versicherung-information" */).then(c => wrapFunctional(c.default || c))
export const AnmeldungAnmeldungresponse = () => import('../../components/anmeldung/Anmeldungresponse.vue' /* webpackChunkName: "components/anmeldung-anmeldungresponse" */).then(c => wrapFunctional(c.default || c))
export const AnmeldungRedirectCard = () => import('../../components/anmeldung/RedirectCard.vue' /* webpackChunkName: "components/anmeldung-redirect-card" */).then(c => wrapFunctional(c.default || c))
export const AnmeldungWelcomePreise = () => import('../../components/anmeldung/WelcomePreise.vue' /* webpackChunkName: "components/anmeldung-welcome-preise" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplatesBasicPanelApp = () => import('../../components/reiseTemplates/BasicPanelApp.vue' /* webpackChunkName: "components/reise-templates-basic-panel-app" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplatesBasicPanelService = () => import('../../components/reiseTemplates/BasicPanelService.vue' /* webpackChunkName: "components/reise-templates-basic-panel-service" */).then(c => wrapFunctional(c.default || c))
export const ReiseAngebotTemplate = () => import('../../components/reiseTemplates/ReiseAngebotTemplate.vue' /* webpackChunkName: "components/reise-angebot-template" */).then(c => wrapFunctional(c.default || c))
export const ZusatzangebotGreetingsText = () => import('../../components/zusatzangebot/GreetingsText.vue' /* webpackChunkName: "components/zusatzangebot-greetings-text" */).then(c => wrapFunctional(c.default || c))
export const ZusatzangebotItem = () => import('../../components/zusatzangebot/Item.vue' /* webpackChunkName: "components/zusatzangebot-item" */).then(c => wrapFunctional(c.default || c))
export const ZusatzangebotNewOrder = () => import('../../components/zusatzangebot/NewOrder.vue' /* webpackChunkName: "components/zusatzangebot-new-order" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2021CB21Template = () => import('../../components/reiseTemplates/2021/CB21Template.vue' /* webpackChunkName: "components/reise-templates2021-c-b21-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2021GB21Template = () => import('../../components/reiseTemplates/2021/GB21Template.vue' /* webpackChunkName: "components/reise-templates2021-g-b21-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2021KB21Template = () => import('../../components/reiseTemplates/2021/KB21Template.vue' /* webpackChunkName: "components/reise-templates2021-k-b21-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2021LB21Template = () => import('../../components/reiseTemplates/2021/LB21Template.vue' /* webpackChunkName: "components/reise-templates2021-l-b21-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2021MallorcaB21Template = () => import('../../components/reiseTemplates/2021/MallorcaB21Template.vue' /* webpackChunkName: "components/reise-templates2021-mallorca-b21-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2021MaltaB21Template = () => import('../../components/reiseTemplates/2021/MaltaB21Template.vue' /* webpackChunkName: "components/reise-templates2021-malta-b21-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2021NB21Template = () => import('../../components/reiseTemplates/2021/NB21Template.vue' /* webpackChunkName: "components/reise-templates2021-n-b21-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2021SB21Template = () => import('../../components/reiseTemplates/2021/SB21Template.vue' /* webpackChunkName: "components/reise-templates2021-s-b21-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2022CB22Template = () => import('../../components/reiseTemplates/2022/CB22Template.vue' /* webpackChunkName: "components/reise-templates2022-c-b22-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2022GB22Template = () => import('../../components/reiseTemplates/2022/GB22Template.vue' /* webpackChunkName: "components/reise-templates2022-g-b22-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2022KB22Template = () => import('../../components/reiseTemplates/2022/KB22Template.vue' /* webpackChunkName: "components/reise-templates2022-k-b22-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2022LB22Template = () => import('../../components/reiseTemplates/2022/LB22Template.vue' /* webpackChunkName: "components/reise-templates2022-l-b22-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2022MallorcaB22Template = () => import('../../components/reiseTemplates/2022/MallorcaB22Template.vue' /* webpackChunkName: "components/reise-templates2022-mallorca-b22-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2022MaltaB22Template = () => import('../../components/reiseTemplates/2022/MaltaB22Template.vue' /* webpackChunkName: "components/reise-templates2022-malta-b22-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2022NB22Template = () => import('../../components/reiseTemplates/2022/NB22Template.vue' /* webpackChunkName: "components/reise-templates2022-n-b22-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2022NBP22Template = () => import('../../components/reiseTemplates/2022/NBP22Template.vue' /* webpackChunkName: "components/reise-templates2022-n-b-p22-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2022SB22Template = () => import('../../components/reiseTemplates/2022/SB22Template.vue' /* webpackChunkName: "components/reise-templates2022-s-b22-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2023CB23Template = () => import('../../components/reiseTemplates/2023/CB23Template.vue' /* webpackChunkName: "components/reise-templates2023-c-b23-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2023GB23Template = () => import('../../components/reiseTemplates/2023/GB23Template.vue' /* webpackChunkName: "components/reise-templates2023-g-b23-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2023KB23Template = () => import('../../components/reiseTemplates/2023/KB23Template.vue' /* webpackChunkName: "components/reise-templates2023-k-b23-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2023LB23Template = () => import('../../components/reiseTemplates/2023/LB23Template.vue' /* webpackChunkName: "components/reise-templates2023-l-b23-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2023MallorcaB23Template = () => import('../../components/reiseTemplates/2023/MallorcaB23Template.vue' /* webpackChunkName: "components/reise-templates2023-mallorca-b23-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2023MaltaB23Template = () => import('../../components/reiseTemplates/2023/MaltaB23Template.vue' /* webpackChunkName: "components/reise-templates2023-malta-b23-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2023NB23Template = () => import('../../components/reiseTemplates/2023/NB23Template.vue' /* webpackChunkName: "components/reise-templates2023-n-b23-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2023NBP23Template = () => import('../../components/reiseTemplates/2023/NBP23Template.vue' /* webpackChunkName: "components/reise-templates2023-n-b-p23-template" */).then(c => wrapFunctional(c.default || c))
export const ReiseTemplates2023SB23Template = () => import('../../components/reiseTemplates/2023/SB23Template.vue' /* webpackChunkName: "components/reise-templates2023-s-b23-template" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
